// eslint-disable-next-line no-unused-vars
import React from 'react';

const indexRedirect = () => {
  if (typeof window !== 'undefined') {
    window.location = 'de';
  }

  return null;
};

export default indexRedirect;
